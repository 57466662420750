'use client';

import IconButton from '@/components/Button/IconButton';
import EmojiText from '@/components/EmojiText/EmojiText';
import { mdiChevronRight, mdiHeartMultiple } from '@mdi/js';
import Icon from '@mdi/react';
import { AnimatePresence, motion, useReducedMotion } from 'framer-motion';
import Link from 'next/link';
import { useState } from 'react';

const FeedbackFormPopup = () => {
	const reduceMotion = useReducedMotion();

	const [isShown, setIsShown] = useState(false);

	return (
		<>
			<AnimatePresence>
				{!isShown && (
					<motion.button
						className="fixed right-0 z-200 flex gap-2 rounded-l-full bg-primary-800 p-4 pe-3 text-primary-100 shadow-2xl max-3xl:bottom-12 max-md:hidden 3xl:top-56 dark:bg-primary-700"
						onClick={() => {
							setIsShown(true);
						}}
						animate={{ opacity: 1, x: 0, scale: 1 }}
						exit={{
							opacity: 0,
							x: reduceMotion ? 0 : '50%',
							scale: reduceMotion ? 1 : 0,
						}}
						transition={{
							type: reduceMotion ? false : 'spring',
						}}
						title="Feedback geven"
					>
						<Icon path={mdiHeartMultiple} size={1} />
						<span className="font-bold text-white max-2xl:hidden">
							Feedback geven
						</span>
					</motion.button>
				)}
			</AnimatePresence>
			<AnimatePresence>
				{isShown && (
					<motion.section
						aria-label="Feedback geven"
						className="fixed right-0 z-200 w-full max-w-sm rounded-l-xl bg-primary-800 p-8 pb-6 text-primary-100 shadow-2xl max-3xl:bottom-12 max-md:hidden 3xl:top-56 dark:bg-primary-900"
						initial={{ opacity: 0, x: reduceMotion ? 0 : '5%' }}
						animate={{ opacity: 1, x: 0 }}
						exit={{ opacity: 0, x: reduceMotion ? 0 : '100%' }}
						transition={{
							type: reduceMotion ? false : 'spring',
							damping: 20,
							stiffness: 60,
							duration: 2,
						}}
					>
						<div
							aria-hidden
							className="absolute -start-6 -top-6 -rotate-6 text-6xl"
						>
							<EmojiText>😇</EmojiText>
						</div>
						<p className="text-lg">
							<strong>Hey</strong>! wat goed dat je een kijkje
							neemt. Nog niet alle content is al compleet. Help je
							ons om deze website zo goed mogelijk te maken voor
							jou?
						</p>
						<div className="flex justify-between gap-4">
							<Link
								href="https://forms.office.com/e/TCzw1tKcC0"
								target="_blank"
								rel="noopener noreferrer"
							>
								<IconButton
									label="Pilot beoordelen"
									title="Geef jouw feedback"
									icon={mdiHeartMultiple}
									className="mt-4 hover:text-slate-900 dark:hover:text-slate-100"
								/>
							</Link>

							<IconButton
								title="Verbergen"
								icon={mdiChevronRight}
								className="mt-4 hover:text-slate-900 dark:hover:text-slate-100"
								onClick={() => {
									setIsShown(false);
								}}
							/>
						</div>
					</motion.section>
				)}
			</AnimatePresence>
		</>
	);
};

export default FeedbackFormPopup;
