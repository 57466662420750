'use client';

export const focusOnArticle = () => {
	const articleStart: HTMLElement | null =
		typeof window !== 'undefined' ? document.querySelector('h1') : null;
	if (articleStart) {
		articleStart.setAttribute('tabindex', '0');
		requestAnimationFrame(() => {
			articleStart.focus();
			articleStart.onblur = () => {
				articleStart.onblur = null;
				requestAnimationFrame(() => {
					articleStart.removeAttribute('tabindex');
				});
			};
		});
	}
};
