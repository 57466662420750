import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/src/app/(main)/[...doc]/FeedbackFormPopup.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/AltDecisionTree/AltDecisionTree.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/ArticleAccessibilityMenu/ArticleAccessibilityMenu.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/EmojiText/EmojiText.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/MDX/CheckList.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/MDX/Iframe.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/MDX/Image.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/MDX/MultipleChoice.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/MDX/Personas.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/MDX/SingleTip.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Navigation/DetailNavigation.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/NewsOverview/NewsOverview.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/ShareButton/ShareButton.tsx")